<!-- 常见问题 -->
<template>
  <div class="flex-col space-y-20 page">

    <div class="flex-col section_3">
      <div class="flex-row justify-between section_4">
        <div class="flex-row space-x-10 group_3">
          <img
            src="../../../../public/static/whattips.png"
            class="image_6"
          />
          <span class="text_2">常见问题</span>
        </div>
      </div>
      <div class="list-box" v-loading="loading">
        <div v-if="list.length > 0">
          <div class="flex-col space-y-22 group_7" v-for="item in list">
            <div class="flex-row justify-between">
              <div class="flex-row space-x-10 group_9">
                <div class="section_5" v-if="item.status == 0">
                  <!---->
                </div>
                <span class="text_8">{{item.name}}</span>
              </div>
              <span class="text_10">{{item.create_time}}</span>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="flex-col space-y-22 group_7">
            <el-empty description="暂无数据"></el-empty>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    components: {},
    data() {
      return {
        loading: false,
        page: 1,
        limit: 10,
        totalpage: 0,
        count: 0,
        list: [],
      };
    },
    beforeCreate() {},
    created() {
      let that = this;
      that.getWhatList();
    },
    methods: {
      //获取问题列表
      getWhatList() {
        let that = this;
        that.loading = true;

        let param = {
          page: that.page,
          limit: that.limit,
        };
        that.$request.getWhatList(param).then((res) => {
          if (res.code == 200) {
            that.list = res.datas;
            if (that.page + 1 < res.total_page) {
              that.page = that.page + 1;
            }

            that.totalpage = res.total_page;
            that.count = res.count;
            //that.$store.commit('hideLoading')
            that.loading = false;
          }
        })
      },
    }
  };
</script>

<style scoped lang="css">
  .space-y-20>*:not(:first-child) {
    margin-top: 1.25rem;
  }

  .page {
    padding-bottom: 15.56rem;
    background-color: #f5f6fa;
    width: calc(100% - 200px) !important;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
  }

  .section_1 {
    padding: 0.63rem 1.25rem 0.63rem 3.13rem;
    background-color: #ffffff;
  }

  .list-box {
    background: #fff;
    padding-bottom: 1.2rem;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }

  .space-x-12>*:not(:first-child) {
    margin-left: 0.75rem;
  }

  .group {
    margin: 0.13rem 0;
    overflow: hidden;
    width: 15.56rem;
    height: 2.88rem;
  }

  .image {
    flex-shrink: 0;
    width: 2.88rem;
    height: 2.88rem;
  }

  .text {
    flex-shrink: 0;
    align-self: center;
    color: #3d3d3d;
    font-size: 1.5rem;
    font-family: Source Sans Pro;
    font-weight: 900;
    line-height: 1.41rem;
  }

  .space-x-15>*:not(:first-child) {
    margin-left: 0.94rem;
  }

  .group_1 {
    overflow: hidden;
    width: 12.88rem;
    height: 3.13rem;
  }

  .space-x-5>*:not(:first-child) {
    margin-left: 0.31rem;
  }

  .group_2 {
    overflow: hidden;
    width: 5.63rem;
    height: 3.13rem;
  }

  .image_1 {
    flex-shrink: 0;
    width: 2.5rem;
    height: 3.13rem;
  }

  .text_1 {
    align-self: center;
    color: #3d3d3d;
    font-size: 0.94rem;
    font-family: Source Sans Pro;
    font-weight: 700;
    line-height: 0.88rem;
  }

  .section_2 {
    align-self: center;
    background-color: #d8d8d8;
    width: 0.094rem;
    height: 0.81rem;
  }

  .image_2 {
    align-self: center;
    width: 1.13rem;
    height: 1.13rem;
  }

  .section_3 {
    /* margin-right: 1.25rem; */
    padding: 1.25rem 1.25rem 1.88rem;
    align-self: flex-start;
    /* background-color: #ffffff; */
    border-radius: 0.63rem;
    width: 97.5%;
  }

  .section_4 {
    padding: 0.94rem 0.94rem;
    background-color: #0074ff;
    border-radius: 0.63rem 0.63rem 0px 0px;
  }

  .space-x-10>*:not(:first-child) {
    margin-left: 0.63rem;
  }

  .group_3 {
    overflow: hidden;
    width: 7.63rem;
    height: 2.5rem;
  }

  .image_6 {
    flex-shrink: 0;
    border-radius: 0.63rem;
    width: 2.5rem;
    height: 2.5rem;
  }

  .text_2 {
    align-self: center;
    color: #ffffff;
    font-size: 1.13rem;
    font-family: Source Sans Pro;
    font-weight: 700;
    line-height: 1.06rem;
  }

  .text-wrapper {
    padding: 0.75rem 0;
    background-color: #ffffff;
    border-radius: 0.63rem;
    width: 8rem;
    height: 2.35rem;
    cursor: pointer;
    align-self: center;
  }

  .text_3 {
    color: #0074ff;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    font-weight: 900;
    line-height: 0.84rem;
  }

  .text-wrapper_1 {
    padding: 0.69rem 0 0.81rem;
    background-color: #ffffff;
    border-radius: 0.63rem;
    width: 3.63rem;
    height: 2.35rem;
    cursor: pointer;
    align-self: center;
  }

  .text_4 {
    color: #0074ff;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    font-weight: 900;
    line-height: 0.81rem;
  }

  .space-y-21>*:not(:first-child) {
    margin-top: 1.31rem;
  }

  .group_5 {
    margin: 1.25rem 0.94rem 0;
    padding-bottom: 1.53rem;
    overflow: hidden;
    border-bottom: solid 0.024rem #d8d8d8;
  }

  .text-wrapper_2 {
    padding: 0.22rem 0 0.31rem;
    overflow: hidden;
    /* width: 6.75rem; */
    height: 1.63rem;
  }

  .text_5 {
    color: #000000;
    font-size: 1.13rem;
    font-family: Source Sans Pro;
    font-weight: 700;
    line-height: 1.06rem;
  }

  .text_6 {
    margin-right: 0.66rem;
    align-self: center;
    color: #9a9da7;
    font-size: 0.94rem;
    font-family: Source Sans Pro;
    line-height: 0.63rem;
  }

  .text_7 {
    align-self: flex-start;
    color: #545964;
    font-size: 0.94rem;
    font-family: Source Sans Pro;
    font-weight: 900;
    line-height: 0.94rem;
  }

  .space-y-22>*:not(:first-child) {
    margin-top: 1.38rem;
  }

  .group_7 {
    margin: 1.25rem 0.94rem 0;
    padding-bottom: 1.56rem;
    overflow: hidden;
    border-bottom: solid 0.024rem #d8d8d8;
  }

  .group_9 {
    padding: 0.22rem 0 0.31rem;
    overflow: hidden;
    width: 8rem;
    height: 1.63rem;
  }

  .section_5 {
    margin-top: 0.41rem;
    flex-shrink: 0;
    background-color: #f26061;
    border-radius: 50%;
    width: 0.63rem;
    height: 0.63rem;
  }

  .text_8 {
    color: #000000;
    font-size: 1.13rem;
    font-family: Source Sans Pro;
    font-weight: 700;
    line-height: 1.06rem;
  }

  .text_10 {
    margin-right: 0.66rem;
    align-self: center;
    color: #9a9da7;
    font-size: 0.94rem;
    font-family: Source Sans Pro;
    line-height: 0.63rem;
  }

  .text_12 {
    align-self: flex-start;
    color: #545964;
    font-size: 0.94rem;
    font-family: Source Sans Pro;
    font-weight: 900;
    line-height: 0.88rem;
  }

  .group_11 {
    padding: 0.22rem 0 0.34rem;
    overflow: hidden;
    width: 5.75rem;
    height: 1.63rem;
  }

  .text_15 {
    align-self: flex-start;
    color: #545964;
    font-size: 0.94rem;
    font-family: Source Sans Pro;
    font-weight: 900;
    line-height: 0.91rem;
  }

  .text_18 {
    align-self: flex-start;
    color: #545964;
    font-size: 0.94rem;
    font-family: Source Sans Pro;
    font-weight: 900;
    line-height: 0.91rem;
  }

  .space-y-19>*:not(:first-child) {
    margin-top: 1.19rem;
  }

  .group_13 {
    margin: 1.25rem 0.94rem 0;
    padding-bottom: 0.19rem;
    overflow: hidden;
    height: 6.94rem;
  }

  .group_15 {
    padding: 0.22rem 0 0.34rem;
    overflow: hidden;
    width: 8rem;
    height: 1.63rem;
  }

  .text_19 {
    color: #000000;
    font-size: 1.13rem;
    font-family: Source Sans Pro;
    font-weight: 700;
    line-height: 1.06rem;
  }

  .text_20 {
    margin-right: 0.66rem;
    align-self: center;
    color: #9a9da7;
    font-size: 0.94rem;
    font-family: Source Sans Pro;
    line-height: 0.63rem;
  }

  .text_21 {
    color: #545964;
    font-size: 0.94rem;
    font-family: Source Sans Pro;
    font-weight: 900;
    line-height: 1.31rem;
  }
</style>
